export const environment = {
  production: true,
  serverURL: "https://api.any.repair/api/v1.0",
  link: "https://api.any.repair",
  onePageLink: "https://any.repair",
  AWS: {
    ACCESSKEY: "AKIAY2ONRMLVTYPKG44X",
    SECRETACCESSKEY: "si99Auj179YdUyZnKekqfVBiqLgaFBrzziRApdc",
    REGION: "us-east-1",
  },
  BUCKET_NAME: 'any.repair',
  BUCKET_FOLDER_NAME: 'Admin',
  DEFAULT_IMAGE: 'https://s3.amazonaws.com/any.repair/Admin/profile/no_image_view_all_screen.png',
  MERCHANTID: "TEST9800084500",
  CURRENCY_LABEL: "JOD",
  CURRENCY_SYMBOL: "ﺩ.ﺍ",
  SOCKET_ENDPOINT: "https://api.any.repair",
  firebase: {
    apiKey: "AIzaSyAXwc66dN4A1hCHHvxXxdKiMD14UtgwZeo",
    authDomain: "eternal-arcana-308706.firebaseapp.com",
    projectId: "eternal-arcana-308706",
    storageBucket: "eternal-arcana-308706.appspot.com",
    messagingSenderId: "471522590522",
    appId: "1:471522590522:web:363557c9744643c7035a03",
    measurementId: "G-7STPQ2FF83",
  },
}; 
